import React, { useCallback, useEffect, useState } from 'react'
// @ts-ignore
// import TimeMe from 'timeme.js'
import { Box, CircularProgress, Grid, ThemeProvider } from '@mui/material'
import {
  LibraryThemeProvider,
  flockTheme,
  TrackedFooter,
} from '@flock/shared-ui'
import { localStore } from '@flock/utils'
import { useQuery } from '@apollo/client'
import {
  Core_SalesforceLead,
  Core_SalesforceScenario,
  LandingGetSalesforceLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import OfferPageWrapper from '../../components/SalesforceOfferPageComponents/OfferPageWrapper'
import { useRecordPageDuration } from '../../components/utils'
import { identify, shouldTrack } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'

import { SalesforceOfferPageData } from '../../components/SalesforceOfferPageComponents/offerPageTypes'
import OfferPage from '../../components/SalesforceOfferPageComponents/OfferPage'
import ErrorCard from '../../components/SalesforceOfferPageComponents/ErrorCard'
import {
  DEFAULT_SALES_PHONE_NUMBER,
  DEFAULT_SALES_EMAIL,
  DEFAULT_SALES_CALENDLY,
} from '../../constants'
import PreliminaryOfferPage from '../../components/OfferPageComponents/PrelimOfferPageComponents/PreliminaryOfferPage'
import { PrelimOfferPageData } from '../../components/OfferPageComponents/offerPageTypes'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type PropertyEstimateProps = {
  params: {
    lead: string
  }
}

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  const { lead: leadId } = params
  useRecordPageDuration()

  // TODO: Implement leadId validation
  const invalidId = typeof window !== 'undefined' && false // && !isLeadId(leadId)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedScenario, setSelectedScenario] = useState<number>(0)

  const [pageData, setPageData] = useState<Partial<SalesforceOfferPageData>>({})
  const [error, setError] = useState(false)

  const { refetch: refetchLead } = useQuery(LandingGetSalesforceLeadDocument, {
    skip: true,
  })

  let salesforceLead = null
  const initializePageData = useCallback(async () => {
    let leadDataResult = null
    try {
      const { data: refetchLeadDataResult } = await refetchLead({
        input: {
          leadId,
        },
      })
      leadDataResult = refetchLeadDataResult
    } catch (e) {
      setError(true)
      return
    }

    if (shouldTrack() && leadId && !localStore?.getItem('leadId')) {
      identify({
        userId: leadId as string,
      })

      localStore?.setItem('leadId', leadId)
    }

    const updatedPageData: Partial<SalesforceOfferPageData> = {}

    try {
      salesforceLead = leadDataResult?.getSalesforceLead
        ?.salesforceLead as Core_SalesforceLead
    } catch (e) {
      setError(true)
      return
    }

    updatedPageData.leadId = leadId

    const salesforceScenario =
      salesforceLead?.scenario as Core_SalesforceScenario
    updatedPageData.scenarios = [salesforceScenario]

    // Lead information
    updatedPageData.fullName = salesforceLead?.fullName || ''
    updatedPageData.firstName = salesforceLead?.fullName?.split(' ')[0] || ''

    // Operator information
    updatedPageData.operator = {
      phoneNumber:
        salesforceLead?.owner?.phoneNumber || DEFAULT_SALES_PHONE_NUMBER,
      email: salesforceLead?.owner?.email || DEFAULT_SALES_EMAIL,
      calendlyUrl:
        salesforceLead?.owner?.calendlyLink || DEFAULT_SALES_CALENDLY,
    }
    // Opportunity information
    const date2020 = new Date(2020, 0, 1)
    const offerDate =
      new Date(salesforceLead?.scenario?.offerDate) > date2020
        ? new Date(salesforceLead?.scenario?.offerDate)
        : new Date()
    const thirtyDaysInTheFuture = new Date()
    thirtyDaysInTheFuture.setDate(offerDate.getDate() + 30)
    updatedPageData.expirationDate = thirtyDaysInTheFuture

    // Scenario information
    updatedPageData.selectedScenario = selectedScenario
    updatedPageData.setSelectedScenario = setSelectedScenario

    setPageData(updatedPageData)
    setLoading(false)
  }, [leadId, selectedScenario])

  useEffect(() => {
    if (!invalidId) {
      initializePageData()
    }
  }, [initializePageData, invalidId])

  if (invalidId || error) {
    return (
      <ThemeProvider theme={flockTheme}>
        <LibraryThemeProvider>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
          >
            <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }
  return (
    <>
      <OfferPageWrapper leadId={leadId}>
        {loading ? (
          <Box
            height="auto"
            pt="50px"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <>
            {pageData?.scenarios &&
            pageData?.scenarios[selectedScenario]?.addresses?.some(
              (address) => (address?.valuation?.length || 0) > 0
            ) ? (
              <Box
                height="auto"
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <OfferPage pageData={pageData as SalesforceOfferPageData} />
              </Box>
            ) : (
              <Box
                height="auto"
                position="relative"
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <PreliminaryOfferPage
                  leadUuid={leadId}
                  pageData={
                    {
                      ...pageData,
                      address: pageData?.scenarios?.[selectedScenario]
                        ?.addresses?.[0]?.formattedAddress as string,
                    } as PrelimOfferPageData
                  }
                />
              </Box>
            )}
          </>
        )}

        <SectionLayout name="footer" backgroundColor="gray1.main">
          <Grid item xs={12}>
            <TrackedFooter />
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
